import { useAtomValue } from 'jotai';
import { openFloatingChat } from '@fiverr-private/go_floating_chat_triggers';
import { isFloatingChatActiveAtom } from '@fiverr-private/seller_pages_toolbox';
import { AgencySeller } from '../../types';
import { useIsSellerOnline } from '../useIsSellerOnline';
import { useAgencySellerData } from '../useAgencySellerData';

export const useFloatingChatTrigger = () => {
    const isFloatingChatEnabled = useAtomValue(isFloatingChatActiveAtom);
    const { username, profileImageUrl, displayName } = useAgencySellerData(selectSellerSlice);
    const isOnline = useIsSellerOnline();

    return {
        isFloatingChatEnabled,
        openFloatingChat: () =>
            openFloatingChat({
                data: {
                    recipientUsername: username,
                    seller: {
                        username,
                        name: displayName,
                        imageSrc: profileImageUrl,
                        isOnline,
                    },
                },
            }),
    };
};

function selectSellerSlice(seller: AgencySeller) {
    return {
        displayName: seller.user.profile.displayName,
        username: seller.user.name,
        profileImageUrl: seller.user.profileImageUrl,
    };
}
