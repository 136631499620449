import React from 'react';
import { isArray } from 'lodash';
import { AiDeliveryBadge, BiEventData } from '@fiverr-private/go_shared_ui';
import { translate } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { Container } from '@fiverr-private/layout_components';
import { AiDeliveryBadgeTooltip } from '../AiDeliveryBadgeTooltip/AiDeliveryBadgeTooltip';
import { SellerAiModelsData } from '../../../server/types/SellerAiModelsData';

export const AiDeliveryBadgeWrapper = ({
    sellerName,
    aiModels,
    sellerId,
    pageName,
}: {
    sellerName: string;
    aiModels: SellerAiModelsData;
    sellerId: string;
    pageName: string;
}) => {
    const { isMobile, userId, pageCtxId } = getContext();

    const baseBiEventData: BiEventData = {
        ...(userId && { userId }),
        ...(sellerId && { sellerId: Number(sellerId) }),
        ctxId: pageCtxId,
        pageName,
        aiModelId: aiModels[0].id,
    };

    const biEventData: BiEventData = {
        ...baseBiEventData,
        componentName: 'badge',
    };

    // Background should be an array with colors, if it's a string, convert it to an array
    const background = aiModels[0].colors;
    const gradientColors = isArray(background) ? background : [background];

    return (
        <Container height="20px" marginTop="0.5">
            {isMobile ? (
                <AiDeliveryBadge gradientColors={gradientColors} biEventData={biEventData} />
            ) : (
                <AiDeliveryBadge
                    gradientColors={gradientColors}
                    popoverContentProps={{ disablePadding: true, backgroundColor: 'transparent' }}
                    tooltipContent={
                        <AiDeliveryBadgeTooltip
                            sellerName={sellerName}
                            aiModels={aiModels}
                            description={translate('seller_page_perseus.ai_models.carousel.description')}
                            biEventData={biEventData}
                        />
                    }
                    biEventData={baseBiEventData}
                />
            )}
        </Container>
    );
};
