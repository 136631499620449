import React from 'react';
import { isEmpty, isNil } from 'lodash';
import pathfinder from '@fiverr-private/pathfinder';
import {
    NavigationCardCarousel,
    NavigationCardProps,
    BiEventData,
    ModelGradientProps,
} from '@fiverr-private/go_shared_ui';
import { useSellerAiModels } from '@fiverr-private/seller_pages_toolbox';
import { getContext } from '@fiverr-private/fiverr_context';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';

interface AiModelsCarouselWrapperProps extends Partial<React.ComponentProps<typeof NavigationCardCarousel>> {}
export const AiModelsCarouselWrapper: React.FC<AiModelsCarouselWrapperProps> = (props) => {
    const sellerAIModels = useSellerAiModels();
    const { sellerName, sellerId } = useAgencySellerData((seller: AgencySeller) => ({
        sellerName: seller.user.name,
        sellerId: Number(seller.user.id),
    }));

    const { userId, pageCtxId } = getContext();

    if (isNil(sellerAIModels) || isEmpty(sellerAIModels)) return null;

    const model = sellerAIModels[0];

    const biEventData: BiEventData = {
        ...(userId && { userId }),
        ...(sellerId && { sellerId }),
        ctxId: pageCtxId,
        pageName: 'seller page',
        aiModelId: model.id,
    };

    const aiModelCards: NavigationCardProps[] = sellerAIModels.map(({ id, name, colors }) => {
        const modelGradient: ModelGradientProps = {
            gradient: 'image',
            colors,
            scale: 'm',
            rotate: 0,
            animate: 'hover',
        };

        return {
            id,
            title: name,
            cardSize: 'md',
            modelGradientProps: modelGradient,
            buttonProps: {
                href: pathfinder('ai_hub_playground_create_tab_new_model_conversation', {
                    seller_username: sellerName,
                    model_slug: id,
                }),
            },
        };
    });

    return (
        <NavigationCardCarousel
            tiles={aiModelCards}
            carouselProps={{ maxWidth: 'max-content' }}
            biEventData={biEventData}
            {...props}
        />
    );
};
