import React from 'react';
import { isEmpty, isNil } from 'lodash';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { Image } from '@fiverr-private/media';
import { logger } from '@fiverr-private/obs';
import { Rating } from '@fiverr-private/orca';
import { Box } from '@fiverr-private/theme';
import { Stack } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';
import {
    AiDeliveryBadgeWrapper,
    formatBigNumbers,
    NewSellerLevelBadge,
    useCurrentPageName,
    useSellerAiModels,
} from '@fiverr-private/seller_pages_toolbox';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { LoyaltyProgramTier } from '@fiverr-private/loyalty_utils';
import { LoyaltyIndicationBanner } from '@fiverr-private/loyalty_indication';
import { bigQuery, EVENT_TYPE } from '../../monitoring/bigQuery';
import { mixpanel } from '../../monitoring/mixpanel';
import { useAgency } from '../../hooks/useAgency';
import { useLoyaltyData } from '../../hooks/useLoyaltyData';
import { AgencyAvatar } from '../AgencyAvatar';
import { BookConsultation } from '../BookConsultation';
import { scrollToTab, TabName } from '../StickyHeader/utils';
import { AgencyContactButton } from '../AgencyContactButton';
import {
    CONTACT_BUTTON_POSITIONS,
    contactButtonBiEnrichment,
} from '../../monitoring/bigQuery/createContactButtonEventEnrichment';
import { useShowContactSellerModalUpdater } from '../../hooks/useShowContactSellerModal';
import { useAgencySellerData } from '../../hooks/useAgencySellerData';
import { AgencySeller } from '../../types';
import { AgencySellerBadge } from '../AgencySellerBadge';
import {
    layoutClass,
    ratingCountClass,
    ratingScoreClass,
    ratingStarClass,
    agencyInfoClass,
    agencyHeroClass,
    agencyHeroWrClass,
    agencyHeroGradientClass,
    buttonClassName,
    contactButtonClass,
    contactButtonWrClass,
} from './styles.ve.css';

const onRenderError = (error: any) => {
    logger.error(new Error('Failed while rendering the AgencyOverview Component'), { cause: error });
};

export const AgencyOverview: React.FC = () => {
    const agency = useAgency();
    const pageName = useCurrentPageName();
    const setShowContactSellerModal = useShowContactSellerModalUpdater();
    const { isPro, oneLinerTitle, displayName, sellerLevel, rating, profileImageUrl, userId, username } =
        useAgencySellerData(selectSellerSlice);

    const sellerAIModels = useSellerAiModels();
    const { score, count } = rating ?? {};

    const onContactOpen = () => {
        setShowContactSellerModal(true);
        mixpanel.event('Contact us clicked');
    };

    const biEnrichment = contactButtonBiEnrichment({ position: CONTACT_BUTTON_POSITIONS.HEADER });

    const contactButtonProps = {
        biEnrichment,
        source: pageName,
        onContactOpen,
        className: contactButtonWrClass,
        buttonClassName: `${contactButtonClass} ${buttonClassName}`,
        title: translate('agency_page_perseus.contact_us'),
    };

    const agencyContactButtonProps = {
        source: CONTACT_BUTTON_POSITIONS.HEADER,
        biEnrichment,
        className: `${contactButtonClass} ${buttonClassName}`,
    };

    const handleRatingsClick = () => {
        scrollToTab(TabName.Reviews);

        bigQuery.clickEvent(EVENT_TYPE.CLICKED_ON_AGENCY_REVIEWS);
        mixpanel.event('Agency Reviews clicked');
    };

    const loyaltyData = useLoyaltyData();

    const showLoyaltyBanner =
        (loyaltyData?.tier === LoyaltyProgramTier.GOLD && Boolean(isPro)) ||
        loyaltyData?.tier === LoyaltyProgramTier.PLATINUM;

    const showAiDeliveryBadge = !isEmpty(sellerAIModels) && !isNil(sellerAIModels);

    return (
        <ErrorBoundary onError={onRenderError}>
            <Box display="flex" justifyContent="spaceBetween" className={layoutClass}>
                <Box position="relative" paddingRight="5" className={agencyInfoClass}>
                    <AgencyAvatar isPro={Boolean(isPro)} avatarUrl={profileImageUrl as string} />
                    <Stack alignItems="center" wrap="wrap" marginTop="6" marginBottom="4" gap="2">
                        {/* Keep as h1 for SEO purposes */}
                        <Typography
                            aria-label="Public Name"
                            as="h1"
                            size="b_xxl"
                            fontWeight="semibold"
                            color="grey_1200"
                            marginRight="3"
                        >
                            <I18n k="agency_page_perseus.hello_we_are" params={{ agencyName: displayName }} />
                        </Typography>
                        <AgencySellerBadge />
                        <Box>
                            <NewSellerLevelBadge sellerLevel={sellerLevel} />
                        </Box>
                        {showAiDeliveryBadge && (
                            <AiDeliveryBadgeWrapper
                                sellerName={username}
                                aiModels={sellerAIModels}
                                sellerId={userId}
                                pageName="agency_page"
                            />
                        )}
                    </Stack>
                    <Typography size="h_xl" fontWeight="semibold" color="grey_1200" marginBottom="5" width="544px">
                        {oneLinerTitle}
                    </Typography>

                    {score && count && (
                        <span aria-label="Rating">
                            <Rating
                                score={score}
                                ratingsCount={formatBigNumbers(count)}
                                starClassName={ratingStarClass}
                                scoreClassName={ratingScoreClass}
                                ratingsCountClassName={ratingCountClass}
                                onRatingsClick={handleRatingsClick}
                                scoreMinimumFractionDigits={1}
                                scoreMaximumFractionDigits={1}
                            />
                        </span>
                    )}
                    <Stack
                        gap="4"
                        marginTop="7"
                        alignItems={{ default: 'stretch', sm: 'center' }}
                        direction={{ default: 'column', sm: 'row' }}
                    >
                        <AgencyContactButton {...agencyContactButtonProps} contactButtonProps={contactButtonProps} />
                        <BookConsultation buttonProps={{ size: 'md', className: buttonClassName }} source={pageName} />
                    </Stack>
                    {showLoyaltyBanner && loyaltyData && (
                        <Stack marginY="6">
                            <LoyaltyIndicationBanner
                                type="agency_page"
                                loyaltyIndicationData={loyaltyData}
                                gigInfo={{
                                    isProAgency: Boolean(isPro),
                                    gigPriceInUSDCents: 0,
                                    agencyName:
                                        displayName ||
                                        translate('agency_page_perseus.fallback_agency_name_to_loyalty_banner'),
                                }}
                            />
                        </Stack>
                    )}
                </Box>

                {agency.profileBackgroundUrl && (
                    <Box
                        position="relative"
                        display="flex"
                        direction="rowReverse"
                        flexGrow="1"
                        className={agencyHeroWrClass}
                    >
                        <Image
                            objectFit="cover"
                            alt="Agency Cover Image"
                            className={agencyHeroClass}
                            src={agency.profileBackgroundUrl as string}
                        />
                        <Box className={agencyHeroGradientClass} />
                    </Box>
                )}
            </Box>
        </ErrorBoundary>
    );
};

const selectSellerSlice = ({
    user: { id, name, profile, profileImageUrl },
    rating,
    sellerLevel,
    isPro,
    oneLinerTitle,
    sellerAIModels,
}: AgencySeller) => ({
    userId: id,
    username: name,
    rating,
    sellerLevel,
    isPro,
    displayName: profile.displayName,
    name,
    oneLinerTitle,
    profileImageUrl,
    sellerAIModels,
});
