export const AGENCY_ROLLOUTS = {
    FALCONS_AGENCIES_AI_BRIEF: 'falcons_agencies_ai_brief',
    HAWKS_FIVERR_AI_AGENCY_PAGE: 'hawks_fiverr_ai_agency_page',
    HAWKS_FIVERR_AI_ENTRY_POINTS: 'hawks_fiverr_ai_entry_points',
    STARLINGS_BOOSTING_NEW_GIGS: 'starlings_boosting_new_gigs',
    STARLINGS_FIVERR_GO_FLOATING_CHAT: 'starlings_fiverr_go_floating_chat',
    STARLINGS_FIVERR_GO_INBOX: 'starlings_fiverr_go_inbox',
    ZEBRAS_HOURLY_RATE: 'zebras_hourly_rate',
} as const;

export type AgencyRollouts = (typeof AGENCY_ROLLOUTS)[keyof typeof AGENCY_ROLLOUTS];
